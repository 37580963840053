
import React from 'react'
import Divider from 'components/Divider'
import { Header, Content, Text, Body, Logo } from './style'
import { useAuth } from 'hooks/useAuth'


const PrivacyPolicy = () => {
  const { isSignedIn } = useAuth()
  return (
    <>
      {!isSignedIn && <Header>
        <Content>
          <Logo />
          <Divider type='vertical' />
          <Text>
            Política de Privacidade
          </Text>
        </Content>
      </Header>}

      <div style={{ backgroundColor: 'white' }}>
        <Body>

          <p>
            <b>Data de entrada em vigor: 20/02/2024</b>
          </p>
          <p>
            <b>Data última alteração: 20/02/2024</b>
          </p>
          <p>
          Esta Política de Privacidade descreve como são coletados, usados e compartilhados dados pessoais quando você utiliza o aplicativo agFarmus Fieldscout, disponível na Google Play Store.
          </p>
          <h3 style={{ textAlign: 'center', paddingTop: 15 }}>
            <b>Informações Coletadas</b>
          </h3>
          <p>
            <b>Informações da Conta</b>
          </p>
          <p>
          Coletamos informações pessoais quando você se registra ou inicia sessão em nossa plataforma. Isso pode incluir seu nome, endereço de e-mail, senha e outros dados de contato.
          </p>

          <p>
            <b>Informações de Imagens</b>
          </p>
          <p>
          Utilizamos as imagens coletadas para realizar análises e processamentos a fim de apresentar os resultados esperados aos usuários. Para realizar essas tarefas, empregamos plataformas de terceiros especializadas em análise de imagens.
          </p>
          <h3 style={{ textAlign: 'center', paddingTop: 15 }}>
            <b>Informações de Uso</b>
          </h3>
          <p>
          Coletamos informações sobre como você utiliza nosso aplicativo, incluindo suas interações com as características disponíveis.
          </p>
          <h3 style={{ textAlign: 'center', paddingTop: 15 }}>
            <b>Uso das Informações</b>
          </h3>
          <ol
            style={{
              margin: 10,
              padding: 3
            }}
          >
          Os dados pessoais coletados são utilizados para: <br />
          - Facilitar a funcionalidade do aplicativo.<br />
          - Processar imagens.<br />
          - Melhorar e personalizar sua experiência no aplicativo.<br />
          - Comunicar-nos contigo sobre atualizações, promoções e suporte.<br />
          - Cumprir obrigações legais.

          </ol>
          <h3 style={{ textAlign: 'center', paddingTop: 15 }}>
            <b>Compartilhamento de Informações</b>
          </h3>
          <ol
            style={{
              margin: 10,
              padding: 3
            }}
          >
          As informações pessoais dos usuários não serão vendidas nem compartilhadas com terceiros, exceto nas seguintes situações: <br />
          - Com o consentimento do usuário.<br />
          - Para cumprir obrigações legais.<br />
          - Com fornecedores de serviços que nos ajudam na operação do aplicativo.<br />
          - Em caso de fusão, aquisição ou venda de ativos, onde as informações dos usuários possam ser transferidas como parte do processo.
          </ol>
          <h3 style={{ textAlign: 'center', paddingTop: 15 }}>
            <b>Segurança</b>
          </h3>
          <p>
          Tomamos medidas razoáveis para proteger seus dados pessoais contra acesso não autorizado, uso, alteração ou destruição.
          </p>
          <h3 style={{ textAlign: 'center', paddingTop: 15 }}>
            <b>Seus Direitos</b>
          </h3>
          <p>
          Você tem o direito de acessar seus dados pessoais e solicitar sua correção, exclusão ou restrição de uso. Para exercer esses direitos, entre em contato conosco através dos detalhes de contato fornecidos abaixo.
          </p>
          <h3 style={{ textAlign: 'center', paddingTop: 15 }}>
            <b>Alterações nesta Política de Privacidade</b>
          </h3>
          <p>
          Podemos atualizar esta Política de Privacidade periodicamente para refletir mudanças em nossas práticas de informações. Recomendamos que você revise periodicamente esta política para se manter informado sobre como estamos protegendo seus dados.
          </p>
          <h3 style={{ textAlign: 'center', paddingTop: 15 }}>
            <b>Contato</b>
          </h3>
          <p>
          Para qualquer dúvida sobre esta Política de Privacidade ou sobre seus dados pessoais, entre em contato conosco em:
            <ol
              style={{
                margin: 10,
                padding: 3
              }}
            >
            - Email: suporte@agfarmus.com <br />
            - Endereço: Av. Marginal Gelindo Desiderato Stefanuto, Campo Mourão - PR
            </ol>
          </p>

        </Body>
      </div>
    </>

  )
}

export default PrivacyPolicy